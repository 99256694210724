<template>
  <div style="padding: 20px">
    <!-- 面包屑 -->
    <el-breadcrumb>
      <el-breadcrumb-item :to="{path:'/welcome'}">首页</el-breadcrumb-item>
      <el-breadcrumb-item>农识资讯发布</el-breadcrumb-item>
      <el-breadcrumb-item>编辑</el-breadcrumb-item>
      <a @click="goBack" href="#" style="color: red">
        <span>返回上一级</span>
      </a>
    </el-breadcrumb>
    <div>
      <a-card>
        <a-form-model
            ref="rulesArticleForm"
            :model="articleInfo"
            :rules="systemRules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 15 }"
        >
          <a-row :gutter="20" type="flex" justify="center">
            <a-col :span="24">
              <!--标题-->
              <a-form-model-item label="标题" prop="title">
                <a-input v-model="articleInfo.title" placeholder="请输入标题"/>
              </a-form-model-item>
              <!--作者-->
              <a-form-model-item label="作者" prop="author">
                <a-input v-model="articleInfo.author" placeholder="请输入作者"/>
              </a-form-model-item>
              <!--时间-->
              <a-form-model-item label="时间" prop="timeT">
                <a-date-picker
                    style="width: 100%;"
                    v-model="timeT"
                    :format="dateFormat"
                    placeholder="请选择"></a-date-picker>
              </a-form-model-item>
              <!--图片、视频上传-->
              <a-form-model-item label="图片/视频">
                <div style="display: flex;">
                  <!-- 图片上传 -->
                  <div>
                    <a-upload
                        name="file"
                        :multiple="false"
                        :showUploadList="false"
                        :action="imgUploadSrc"
                        :beforeUpload="handleImageBeforeUpload"
                        @change="handleImageChange"
                    >
                      <div>

                        <a-button icon="upload" style="width: 150px">
                          点击上传图片
                        </a-button>
                      </div>
                    </a-upload>
                  </div>
                  <!-- 视频上传 -->
                  <div style="margin-left: 20px">
                    <a-upload
                        name="file"
                        :multiple="false"
                        :showUploadList="false"
                        :action="videoUploadSrc"
                        :beforeUpload="handleVideoBeforeUpload"
                        @change="handleVideoChange"
                    >
                      <div>
                        <a-button icon="upload" style="width: 150px">
                          点击上传视频
                        </a-button>
                      </div>
                    </a-upload>
                  </div>
                  <div style="margin-left: 20px">
                    <a-button style="width: 150px" type="danger" @click="cleanImgOrVideo">
                      清空图片视频
                    </a-button>
                  </div>
                </div>
                <div class="file-show-wrapper">
                  <div v-if="isShowImg" class="img-upload">
                    <img :src="imgPathAll" alt=""/>
                  </div>

                  <div v-if="isShowVideo" class="video-upload">
                    <video :src="videoPathAll" controls="controls"></video>
                  </div>
                </div>
              </a-form-model-item>
              <!--新闻内容-->
              <a-form-model-item label="新闻内容" prop="text">
                <a-textarea
                    v-model="articleInfo.text"
                    placeholder="请输入..."
                    :auto-size="{ minRows: 15}"
                />
              </a-form-model-item>
              <!--操作-->
              <a-form-model-item label="操作">
                <a-button type="primary" @click="handleSave()">保存</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
    </div>
  </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: "EditArticle",

        data() {
            return {
                dateFormat: 'YYYY-MM-DD HH:mm:ss',
                //图片上传地址
                imgUploadSrc: this._axios_env.SERVER+`/file/img?access_token=${localStorage.getItem('access_token').substring(6,localStorage.getItem('access_token').length)}`,
                //视频上传地址
                videoUploadSrc: this._axios_env.SERVER+`/file/file?access_token=${localStorage.getItem('access_token').substring(6,localStorage.getItem('access_token').length)}`,
                //文章信息对象
                articleInfo: {
                    id: '',
                    imgPath: '',
                    videoPath: '',
                    title: '',
                    author: '',
                    timeStr: '',
                    text: '',
                },
                imgPathAll: '',
                videoPathAll: '',
                timeT: this.moment(new Date()),
                isUpload: false,
                //表单验证规则
                systemRules: {
                    title: [
                        {required: true, message: '请填写标题', trigger: 'blur'},
                    ],
                    author: [
                        {required: true, message: '请填写作者', trigger: 'blur'},
                    ],
                    text: [
                        {required: true, message: '请填写文章内容', trigger: 'blur'},
                    ],
                },
            }
        },

        mounted() {
            let item = this.$route.query.item;
            let data = JSON.parse(item);
            this.articleInfo = {
                id:data.id,
                imgPath: data.imgPath,
                videoPath: data.videoPath,
                title: data.title,
                author: data.author,
                text: data.text,
            };
            this.timeT = this.moment(data.time);
            this.imgPathAll = data.imgPathAll;
            this.videoPathAll = data.videoPathAll;
        },

        computed: {
            //判断是否显示Logo
            isShowImg() {
                console.log("计算");
                if (this.articleInfo.imgPath === "") {
                    return false;
                } else {
                    const reg = /null/;
                    return !reg.test(this.articleInfo.imgPath)
                }
            },
            isShowVideo() {
                console.log("计算");
                if (this.articleInfo.videoPath === "") {
                    return false;
                } else {
                    const reg = /null/;
                    return !reg.test(this.articleInfo.videoPath)
                }
            }
        },

        methods: {
            cleanImgOrVideo() {
                this.imgPathAll = '';
                this.videoPathAll = '';
                this.articleInfo.imgPath = '';
                this.articleInfo.videoPath = '';
            },
            handleImageBeforeUpload(file, fileList) {
                const reg = /\.(png|jpg|jpeg|gif)$/ig;
                const filename = file.name
                if (reg.test(filename) === false) {
                    this.$message.error('请上传正确的图片格式！')
                    return false;
                }
                this.$message.success('上传中,请耐心等待');
            },

            //上传图片改变时的状态
            handleImageChange({file, fileList, event}) {
                if (file['response'] !== undefined) {
                    this.imgPathAll = file['response'].data.allPath;
                    this.articleInfo.imgPath = file['response'].data.path;
                }
            },

            //上传视频之前的钩子
            handleVideoBeforeUpload(file, fileList) {
                const reg = /\.mp4$/ig;
                const filename = file.name
                if (reg.test(filename) === false) {
                    this.$message.error('请上传正确的视频格式！')
                    return false;
                }
                this.$message.success('上传中,请耐心等待');
            },

            //上传视频改变时的状态
            handleVideoChange({file, fileList, event}) {

                if (file['response'] !== undefined) {
                    this.videoPathAll = file['response'].data.allPath;
                    this.articleInfo.videoPath = file['response'].data.path;
                }
            },

            //返回
            goBack() {
                this.$router.push('/agriculturalPublish');
            },

            // 修改
            handleSave() {
                this.articleInfo.timeStr = this.timeT.format('YYYY-MM-DD HH:mm:ss');
                this.$refs.rulesArticleForm.validate(async valid => {
                    if (valid) {
                        const {data: res} = await this.http.news.updateNews(this.articleInfo);
                        if (res.status == 200) {
                            this.$message.success(res.msg);
                            this.goBack();
                        } else {
                            this.$message.error(res.msg);
                        }
                    } else {
                        return false;
                    }
                });
            },
        },
    }
</script>

<style scoped>


  .file-show-wrapper {
    display: flex;
    margin-top: 20px;
  }

  .img-upload {
    width: 300px;
    height: 150px;
  }

  .img-upload img {
    width: 100%;
    height: 100%;
  }

  .video-upload {
    width: 300px;
    height: 150px;
    margin-left: 20px;
  }

  .video-upload video {
    width: 100%;
    height: 100%;
  }
</style>

